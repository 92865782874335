import service from '@/utils/service'

// 获取用户信息接口
export const getUserInfo = (params) =>
  service({
    url: '/api/user/login/exchange',
    method: 'get',
    params,
  })

// 修改密码
export const updatePassword = (data) =>
  service({
    url: '/api/user/updatePassword',
    method: 'post',
    data,
  })

// 查询用户
export const getUserList = (params) =>
  service({
    url: '/api/user/findByUserCodeList',
    method: 'get',
    params,
  })

// 获取常用联系人
export const getCareUserList = () =>
  service({
    url: '/api/user/getCareUserList',
    method: 'get',
  })

// 查询用户列表 (新出接口)
export const getUserListNew = (params) =>
  service({
    url: '/api/user/searchLikeUser',
    method: 'get',
    params,
  })

// 编辑用户接口
export const editUser = (data) =>
  service({
    url: '/api/user/edit',
    method: 'post',
    data,
  })

// 搜索推荐人员
export const recommendUser = (params) =>
  service({
    url: 'api/search/getCreatorTopThreeList',
    method: 'get',
    params,
  })
