<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
  import Vue from 'vue'
  import storage from 'store'
  import { useSystemStore } from '@/store/modules/system'
  import { ConfigProvider } from '@idc-design/web-ui'
  import { loadLanguageAsync, LANGUAGE_EMNU } from '@/locales'

  Vue.use(ConfigProvider)

  export default {
    name: 'App',
    data() {
      return {
        systemStore: useSystemStore(),
        locale: null,
      }
    },
    watch: {
      'systemStore.language': {
        async handler() {
          const lang = LANGUAGE_EMNU.find((item) => item.key === this.systemStore.language.key)
          this.locale = lang.antValue
        },
        immediate: true,
      },
    },
    created() {
      // 判断用户之前选择的语言并配置
      const currentLanguage = storage.get('language')
      if (currentLanguage) {
        loadLanguageAsync(currentLanguage.key)
      }
    },
  }
</script>
