import service from '@/utils/service'

// 分享-默认用户列表
export const getShareDefaultUserList = (params) =>
  service({
    url: '/api/share/getUserList',
    method: 'get',
    params,
  })

// 分享-搜索用户列表
export const getShareUserList = (params) =>
  service({
    url: '/api/share/searchLikeUser',
    method: 'get',
    params,
  })

// 分享-专题
export const shareTopic = (data) =>
  service({
    url: '/api/share/shareTopic',
    method: 'post',
    data,
  })

// 分享-图片/视频
export const shareImageVideo = (data) =>
  service({
    url: '/api/share/shareImageVideo',
    method: 'post',
    data,
  })

// 分享列表
export const getSharedList = (params) =>
  service({
    url: '/api/personalCenter/myShared',
    method: 'get',
    params,
  })

// 我分享的，分享给我的-删除
export const deleteShareList = (params, data) =>
  service({
    url: '/api/share/batchDelete',
    method: 'post',
    params,
    data,
  })
