<template>
  <svg
    :class="['svg-icon', { 'is-click': isClick }]"
    aria-hidden="true"
    :width="width"
    :height="height"
    @click="onClick"
  >
    <use :xlink:href="symbolId" :fill="fill" />
  </svg>
</template>

<script>
  export default {
    name: 'SvgIconComponent',
    props: {
      name: {
        type: String,
        required: true,
      },
      width: {
        type: Number,
        default: 19,
      },
      height: {
        type: Number,
        default: 19,
      },
      fill: {
        type: String,
        default: '#ffffff',
      },
      isClick: Boolean,
    },
    data() {
      return {}
    },
    computed: {
      symbolId() {
        return `#${this.name}`
      },
    },
    methods: {
      onClick() {
        this.$emit('click')
      },
    },
  }
</script>

<style lang="less" scoped>
  .svg-icon {
    fill: currentColor;
    overflow: hidden;
    &:focus {
      outline: none;
    }
  }
  .is-click {
    cursor: pointer;
  }
</style>
