import service from '@/utils/service'

// 获取品牌和车型
export const getUserBrandAndModel = (params) =>
  service({
    url: '/api/home/getUserBrandAndModel',
    method: 'get',
    params,
  })

// 切换品牌
export const cutoverBrand = (params) =>
  service({
    url: '/api/user/cutoverBrand',
    method: 'get',
    params,
  })

// 获取所有品牌车型
export const getAllBrandAndModel = (params) =>
  service({
    url: '/api/home/getAllBrandAndModel',
    method: 'get',
    params,
  })

// 获取标签列表
export const getTagList = (params) =>
  service({
    url: '/api/tag/getTagList',
    method: 'get',
    params,
  })

// 根据阶段属性获取专题属性
export const getSubjectList = (params) =>
  service({
    url: '/api/tag/getTagSubjectList',
    method: 'get',
    params,
  })

// 根据专题获取所有车型
export const getAllModelByTopicId = (params) =>
  service({
    url: '/api/home/getAllModelByTopicId',
    method: 'get',
    params,
  })

// 获取标签数据
export const getBrandTagList = (params) =>
  service({
    url: '/api/tag/getBrandTagList',
    method: 'get',
    params,
  })
