import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zhCN from './lang/zh-CN.json'
import enUS from './lang/en-US.json'
import idcDesignEn from '@idc-design/web-ui/es/locale/en_US.js'
import idcDesignZh from '@idc-design/web-ui/es/locale/zh_CN.js'
import 'moment/dist/locale/zh-cn'
import { useSystemStore } from '@/store/modules/system'
import storage from 'store'
import moment from 'moment'
moment.locale('zh-cn')

Vue.use(VueI18n)

export const LANGUAGE_EMNU = [
  {
    key: 'zh-CN',
    value: zhCN,
    momentKey: 'zh-cn',
    antKey: 'zh_CN',
    antValue: JSON.parse(JSON.stringify(idcDesignZh)),
    name: '中文-简体',
  },
  {
    key: 'en-US',
    value: enUS,
    momentKey: 'es-us',
    antKey: 'en_US',
    antValue: JSON.parse(JSON.stringify(idcDesignEn)),
    name: 'English',
  },
]

export const defaultLang = storage.get('language')?.key || 'zh-CN'
export const defaultLangObj = LANGUAGE_EMNU.find((item) => item.key === defaultLang)

const messages = {
  'zh-CN': {
    ...zhCN,
  },
  'en-US': {
    ...enUS,
  },
}

const i18n = new VueI18n({
  silentTranslationWarn: true,
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages,
})

setI18nLanguage(defaultLang)

export function i18nRender(key) {
  return i18n.t(key)
}

export function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
}

export function loadLanguageAsync(lang) {
  // 更新 store
  const langObj = LANGUAGE_EMNU.find((item) => item.key === lang)
  const systemStore = useSystemStore()
  systemStore.updateData({ language: langObj })
  storage.set('language', langObj)
  const locale = langObj.value
  i18n.setLocaleMessage(lang, locale)
  moment.updateLocale(langObj.momentKey, langObj.momentKey)
  setI18nLanguage(lang)
}

export default i18n
