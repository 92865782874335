import * as common from './common'
import * as login from './login'
import * as user from './user'
import * as message from './message'
import * as car from './car'
import * as download from './download'
import * as share from './share'
import * as topic from './topic'
import * as file from './file'
import * as space from './space'
import * as search from './search'

export default Object.assign({}, login, common, user, message, car, download, share, topic, file, space, search)
