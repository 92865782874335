import service from '@/utils/service'

// 获取个人空间数据
export const getPersonalSpaceData = (params) =>
  service({
    url: '/api/personalCenter/folderList',
    method: 'get',
    params,
  })

// 个人空间上传
export const personalSpaceUpload = (data) =>
  service({
    url: '/api/upload/folderUpload',
    method: 'post',
    data,
  })

// 删除个人空间文件
export const deletePersonalSpaceData = (params) =>
  service({
    url: '/api/personalCenter/deleteFolder',
    method: 'get',
    params,
  })

// 更新个人空间文件
export const updatePersonalSpaceData = (params) =>
  service({
    url: '/api/personalCenter/updateFolder',
    method: 'get',
    params,
  })

// 获取个人空间文件夹目录
export const getPersonalSpaceFolderList = (params) =>
  service({
    url: '/api/personalCenter/pathList',
    method: 'get',
    params,
  })

// 移动至文件夹
export const movePersonalSpaceData = (data) =>
  service({
    url: '/api/personalCenter/moveFile',
    method: 'post',
    data,
  })

// 新建文件夹
export const newFolder = (params) =>
  service({
    url: '/api/personalCenter/createFile',
    method: 'get',
    params,
  })

// 批量下载-同步
export const batchDownload = (data) =>
  service({
    url: '/api/obs/fileBatchDownloadNew',
    method: 'post',
    data,
  })

// 批量下载-异步
export const batchDownloadSync = (data) =>
  service({
    url: '/api/obs/fileBatchDownloadSync',
    method: 'post',
    data,
  })

// 转移至专题
export const saveToTopic = (data) =>
  service({
    url: '/api/file/transform',
    method: 'post',
    data,
  })
