import axios from 'axios'
import router from '@/router'
import { blobToJSON } from '@/utils'
import storage from 'store'
import { i18nRender } from '@/locales'
import qs from 'qs'

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/proxy-api' : process.env.VUE_APP_BASE_API_URL,
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  timeout: 60000,
})

// 请求拦截器
instance.interceptors.request.use(
  function (config) {
    const token = storage.get('token')
    const { headers = {} } = config
    if (token) {
      headers.accessToken = token
      config.headers = headers
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  },
)

// 响应拦截器
instance.interceptors.response.use(
  async function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    let { data, headers } = response
    const contentType = headers['content-type']
    const isBlob = data instanceof Blob

    // 如果返回数据的 contentType 为流类型 拼装返回结果
    if (/application\/octet-stream;/.test(contentType)) {
      data = {
        code: '000000',
        body: data,
      }
    }

    // 如果返回数据的contentType为json类型 但是data实际时blob 需要将data转换为json
    if (isBlob && contentType.indexOf('application/json') !== -1) {
      data = await blobToJSON(data)
    }

    if (data.code !== '000000') {
      // code 码为 token 失效状态时需要重新登录
      if (data.code === '300001' || data.code === '400001' || data.code === '500000') {
        storage.remove('token')
        router.replace({
          name: 'Login',
          query: { redirect: router.currentRoute.fullPath }, // 当前的路径
        })
      }
      window.$toast.error(data.message || i18nRender('Utils.Service.requestError'))
      return Promise.reject(new Error({ code: data.code, message: data.message }))
    }

    return {
      ...data,
      headers,
    }
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    window.$toast.error(error.message || i18nRender('Utils.Service.requestFail'))
    return Promise.reject(error)
  },
)

export default instance
