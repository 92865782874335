import Vue from 'vue'
import VueRouter from 'vue-router'
import { constantRoutes, permissionRoutes, redirect404Route } from '@/router/routes'
import { getQueryString } from '@/utils'
import storage from 'store'
import { getDingTalkPlatform, getDingTalkCodeSync, openLink } from '@/utils/dingTalk.js'
import Api from '@/api'
import { i18nRender } from '@/locales'

Vue.use(VueRouter)

const routes = [...constantRoutes, ...permissionRoutes, redirect404Route]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes,
})

router.beforeEach(async (to, from, next) => {
  document.documentElement.scrollTop = 0
  // 判断该路由是否需要登录权限
  if (to.matched.some((record) => record.meta.auth)) {
    const qsToken = getQueryString('token')
    // query 中存在 token
    if (qsToken) {
      storage.set('token', qsToken)
      window.location.href = `${window.location.origin}`
    } else {
      // query不存在最新的token
      const token = storage.get('token')
      if (token) {
        // localstorage 中存在token
        next()
      } else {
        // 不存在 token 需要重新认证
        if (getDingTalkPlatform() !== 'notInDingTalk') {
          // 钉钉环境，走钉钉免登录流程
          try {
            const code = await getDingTalkCodeSync()
            const { body } = await Api.getTokenByCodeSync({ authCode: code })
            if (body.accessToken) {
              openLink(`${window.location.origin}?token=${body.accessToken}`)
                .then(() => {
                  next({ path: '/dingtalk' })
                })
                .catch(() => {
                  window.$toast.error(i18nRender('Router.openLinkErrorToast'))
                })
            }
          } catch (error) {
            console.log(error)
          }
        } else {
          // 跳转到登录页面
          next({
            path: '/login',
            query: {
              redirect: to.fullPath,
            },
          })
        }
      }
    }
  } else {
    next()
  }
})

export default router
