import Vue from 'vue'
import App from './App.vue'
import pinia from './store'
import router from './router'
import i18n from './locales'
import 'amfe-flexible'
import SvgIcon from '@/assets/icons'
import VueToast from '@/plugins/vue-toast'
import VueModal from '@/plugins/vue-modal'

Vue.use(VueToast).use(VueModal).use(SvgIcon)
Vue.config.productionTip = false

new Vue({
  pinia,
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
