/**
 * path: '/'                       路由的路径
 * name:'router-name'              设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    title: '首页'                  设置该路由在侧边栏和导航栏中展示的名字
    noKeepAlive: true              如果设置为 true，则不会被 <keep-alive> 缓存，默认是缓存的，因为需要搭配顶部标签栏使用
    auth: false                    设置为 false，则该页面不会校验是否登录
    permissionCode: 1122           服务器定义的页面权限 code 码，用户权限列表数据会与之匹配判断是否有进入权限
  }
 */

/**
 * constantRoutes
 * 不需要权限的基础页面
 * 所有角色的都可以访问
 */
export const constantRoutes = [
  {
    path: '/',
    redirect: '/main',
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录',
    },
    component: () => import('@/views/login/index.vue'),
  },
  {
    path: '/ssologin',
    name: 'SSOLogin',
    meta: {
      title: '单点登录回调页',
    },
    component: () => import('@/views/ssoLogin/index.vue'),
  },
  {
    path: '/dingtalk',
    name: 'DingTalk',
    meta: {
      title: '钉钉内部空白页面',
    },
    component: () => import('@/views/dingTalkEmpty/index.vue'),
  },
  {
    path: '/404',
    name: 'NotFound',
    meta: {
      title: '404',
    },
    component: () => import('@/views/error/notFound.vue'),
  },
]

/**
 * permissionRoutes
 * 需要用户有该页面权限才可以访问
 * 路由是根据权限动态加入
 */
export const permissionRoutes = [
  {
    path: '/main',
    name: 'main',
    meta: {
      title: 'main',
      auth: true,
    },
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '/main',
        redirect: '/main/home',
      },
      {
        path: '/main/home',
        name: 'Home', // 首页
        component: () => import('@/views/home/index.vue'),
      },
      {
        path: '/main/add_edit_topic/:topicId',
        name: 'AddEditTopic', // 添加编辑专题页
        component: () => import('@/views/addEditTopic/index.vue'),
      },
      {
        path: '/main/add_edit_file/:topicId/:resourceId',
        name: 'AddEditFile', // 添加编辑文件页
        component: () => import('@/views/addEditFile/index.vue'),
      },
      {
        path: '/main/topic_detail/:topicId',
        name: 'TopicDetail', // 专题详情页
        component: () => import('@/views/topicDetail/index.vue'),
      },
      {
        path: '/main/file_detail/:resourceId',
        name: 'FileDetail', // 文件详情页
        component: () => import('@/views/fileDetail/index.vue'),
      },
      {
        path: '/main/topic_member_manage/:topicId',
        name: 'TopicMemberManage', // 专题成员管理
        component: () => import('@/views/topicMemberManage/index.vue'),
      },
      {
        path: '/main/my_content/:currentTab',
        name: 'MyContent', // 我的内容
        component: () => import('@/views/myContent/index.vue'),
      },
      {
        path: '/main/search',
        name: 'Search', // 搜索页
        component: () => import('@/views/search/index.vue'),
      },
      {
        path: '/main/personal_home/:userId',
        name: 'PersonalHome', // 个人主页
        component: () => import('@/views/personalHome/index.vue'),
      },
      {
        path: '/main/personal_edit',
        name: 'PersonalEdit', // 个人资料编辑页
        component: () => import('@/views/personalEdit/index.vue'),
      },
      {
        path: '/main/personal_space/:folderId',
        name: 'PersonalSpace', // 个人空间
        component: () => import('@/views/personalSpace/index.vue'),
      },
    ],
  },
]

/**
 * 404 redirect
 * 需要在动态路由都加入后再加入
 */
export const redirect404Route = {
  path: '/:pathMatch(.*)*',
  redirect: '/404',
  meta: {
    title: '404 redirect',
    hidden: true,
  },
}
