import * as dd from 'dingtalk-jsapi'

// 获取当前的钉钉平台环境
export function getDingTalkPlatform() {
  return dd.env.platform
}

export function getDingTalkCodeSync() {
  return new Promise((resolve, reject) => {
    dd.ready(function () {
      dd.runtime.permission.requestAuthCode({
        corpId: process.env.VUE_APP_DING_TALK_CORP_ID, // 企业id
        onSuccess: function (info) {
          resolve(info.code)
        },
        onFail: function (err) {
          reject(err)
        },
      })
    })
  })
}

export const openLink = (url) => {
  return new Promise((resolve, reject) => {
    dd.ready(function () {
      dd.biz.util.openLink({
        url,
        onSuccess: function (result) {
          resolve(result)
        },
        onFail: function (err) {
          reject(err)
        },
      })
    })
  })
}
