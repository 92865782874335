import { defineStore } from 'pinia'
import { defaultLangObj } from '@/locales'

export const useSystemStore = defineStore('system', {
  state: () => {
    const system = {
      language: { ...defaultLangObj },
      location: null,
    }
    return system
  },
  getters: {},
  actions: {
    // 更新数据
    updateData(data) {
      this.$patch({ ...data })
    },
  },
})
