import { i18nRender } from '@/locales'

export function blobToJSON(data) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(data, 'utf-8')
    reader.onload = function () {
      try {
        resolve(JSON.parse(reader.result))
      } catch (error) {
        reject({ code: '400000', message: `${i18nRender('Utils.Index.blobToJsonError')}${error}` })
      }
    }
  })
}

// 获取query数据
export function getQueryString(field, url) {
  var href = url ? url : window.location.href
  var reg = new RegExp('[?&]' + field + '=([^&]*)', 'i')
  var string = reg.exec(href)
  return string ? string[1] : null
}

// 格式化用户权限数据数据
export function authInit(dataList) {
  function initFn(obj) {
    if (obj.children && obj.children.length) {
      let children = []
      let func = []
      obj.children.map((item) => {
        if (item.menuType === 1) {
          children.push(initFn(item))
        } else {
          func.push(item.title)
        }
      })
      return {
        title: obj.title,
        path: '',
        func,
        children,
      }
    }
    return {
      title: obj.title,
      path: '',
      func: [],
      children: [],
    }
  }
  const result = dataList.map((item) => {
    if (item.menuType === 1) {
      return initFn(item)
    }
    return initFn({
      title: '全局功能',
      children: [item],
    })
  })
  return result
}
