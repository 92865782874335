import service from '@/utils/service'

// 获取未读消息数量接口
export const getUnreadMessages = (params) =>
  service({
    url: '/api/letter/getUnreadMessages',
    method: 'get',
    params,
  })

// 获取消息列表接口
export const getMessageList = (params) =>
  service({
    url: '/api/letter/receiver/list',
    method: 'get',
    params,
  })

// 发送消息
export const sendMessage = (params) =>
  service({
    url: '/api/letter/send',
    method: 'get',
    params,
  })
