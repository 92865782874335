import { Message } from '@idc-design/web-ui'

export default {
  install(Vue) {
    class Toast {
      constructor() {
        this.messageKeyList = []
      }
      success(content, duration = 5) {
        this.toast(content, 'success', duration)
      }
      error(content, duration = 5) {
        this.toast(content, 'error', duration)
      }
      info(content, duration = 5) {
        this.toast(content, 'info', duration)
      }
      warn(content, duration = 5) {
        this.toast(content, 'warn', duration)
      }
      warning(content, duration = 5) {
        this.toast(content, 'warn', duration)
      }

      // 倒计时5s
      toast(content, type, duration) {
        let newKey = ''
        if (this.messageKeyList.length) {
          const lastKey = this.messageKeyList[0]
          newKey = `countdown-${++lastKey.split('-')[1]}`
        } else {
          newKey = `countdown-1`
        }
        this.messageKeyList.unshift(newKey)
        let count = duration
        Message[type]({ content: `${content} (${count}s)`, key: newKey, duration })
        const interval = setInterval(() => {
          count -= 1
          if (count === 0) {
            clearInterval(interval)
            this.messageKeyList.pop()
          } else {
            Message[type]({ content: `${content} (${count}s)`, key: newKey, duration })
          }
        }, 1000)
      }
    }

    const toast = new Toast()
    Vue.prototype.$toast = toast
    window.$toast = toast
  },
}
