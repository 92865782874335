import SvgIcon from '@/components/SvgIcon.vue'
const requireAll = (requireContext) => requireContext.keys().map(requireContext)
const req = require.context('@/assets/icons', false, /\.svg$/)
requireAll(req)

export default {
  install: (app) => {
    // 注册全局组件
    app.component('SvgIcon', SvgIcon)
  },
}
